import React from "react";
import BasicLayout from "main/layouts/BasicLayout/BasicLayout";

export default function AdminCreateAnnouncementsPage() {
    return (
        <BasicLayout>
            <div className="pt-2">
                <h1>Create Announcement</h1>
                <h2>Not implemented yet; coming soon!</h2>
            </div>
        </BasicLayout>
    )
}